<template>
    <div class="content">
        <div
            class="row justify-space-between align-center pb-2"
            :class="`${manHinhPC ? ' pa-4' : ' pa-2'}`"
        >
            <div class="grow logo">
                <img
                    src="@/assets/images/logo-sonphat.png"
                    :style="`${
                        manHinhPC
                            ? 'width: 244px; height: auto'
                            : 'width: 128px; height: auto'
                    }`"
                    alt
                />
            </div>
            <div
                class="shrink font-medium color-success ho_tro"
                :class="`${manHinhPC ? 'font-20' : 'font-16'}`"
            >
                Hỗ trợ: 1900.4751
            </div>
        </div>
        <div class="TraCuuLenh" :class="`${manHinhPC ? 'widthinput' : 'px-2'}`">
            <div
                class="font-bold text"
                :style="`${
                    manHinhPC ? 'font-size: 32px; ' : 'font-size: 24px; '
                }`"
            >
                Tra cứu Vé điện tử
            </div>
            <span class="font-16">Tìm kiếm, tra cứu thông tin vé điện tử</span>

            <DxValidationGroup ref="formValidation">
                <div class="row justify-center mt-4" v-if="manHinhPC">
                    <div class="xs3 mx-2">
                        <DxSelectBox
                            v-model="KieuTimKiem"
                            :dataSource="DanhSachKieuTimKiem || []"
                            label="Tìm kiếm theo"
                            labelMode="floating"
                            display-expr="text"
                            value-expr="id"
                            styling-mode="outlined"
                            no-data-text="Không có dữ liệu!"
                            width="100%"
                            :onItemClick="onItemClick"
                        />
                    </div>
                    <div class="xs7">
                        <div class="row" v-if="KieuTimKiem == 'SoDienThoai'">
                            <div class="xs6 mx-2">
                                <DxDateBox
                                    v-model="ThoiGianXuatBen"
                                    displayFormat="HH:mm dd/MM/yyyy"
                                    type="datetime"
                                    :use-mask-behavior="true"
                                    label="Thời gian xuất bến"
                                    labelMode="floating"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="outlined"
                                    width="100%"
                                    :onEnterKey="TimKiem"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                            <div class="xs6 mx-2">
                                <DxTextBox
                                    v-model="SoDienThoai"
                                    label="Số điện thoại (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    :onEnterKey="TimKiem"
                                    width="100%"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Số điện thoại không được bỏ trống!"
                                        />
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Số điện thoại không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row" v-else-if="KieuTimKiem == 'MaTraCuu'">
                            <div class="xs6 mx-2">
                                <DxTextBox
                                    v-model="MaChuyenDi"
                                    label="Mã chuyến đi"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    width="100%"
                                    :onEnterKey="TimKiem"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Mã chuyến đi không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                            <div class="xs6 mx-2">
                                <DxTextBox
                                    v-model="MaDatCho"
                                    label="Mã đặt chỗ"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    width="100%"
                                    :onEnterKey="TimKiem"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Mã đặt chỗ không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>

                    <div class="xs2 mx-2">
                        <DxButton
                            key="mdi-XACNHAN"
                            type="default"
                            text="TÌM KIẾM"
                            :height="48"
                            styling-mode="contained"
                            width="100%"
                            @click="TimKiem"
                        />
                    </div>
                </div>

                <div class="row justify-center mt-3" v-else>
                    <div class="xs12">
                        <div class="row">
                            <div class="xs12">
                                <DxSelectBox
                                    v-model="KieuTimKiem"
                                    :dataSource="DanhSachKieuTimKiem || []"
                                    label="Tìm kiếm theo"
                                    labelMode="floating"
                                    display-expr="text"
                                    value-expr="id"
                                    styling-mode="outlined"
                                    no-data-text="Không có dữ liệu!"
                                    width="100%"
                                    :onItemClick="onItemClick"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="KieuTimKiem == 'SoDienThoai'">
                            <div class="xs12">
                                <div class="row mt-4">
                                    <div class="xs12">
                                        <DxDateBox
                                            v-model="ThoiGianXuatBen"
                                            displayFormat="HH:mm dd/MM/yyyy"
                                            type="datetime"
                                            :use-mask-behavior="true"
                                            label="Thời gian xuất bến"
                                            labelMode="floating"
                                            dropDownButtonTemplate="customIcon"
                                            validationMessageMode="always"
                                            styling-mode="outlined"
                                            width="100%"
                                            :onEnterKey="TimKiem"
                                        >
                                            <template #customIcon="{}">
                                                <DxButton
                                                    icon="mdi mdi-calendar"
                                                />
                                            </template>
                                        </DxDateBox>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="xs12">
                                        <DxTextBox
                                            v-model="SoDienThoai"
                                            label="Số điện thoại (*)"
                                            labelMode="floating"
                                            styling-mode="outlined"
                                            validationMessageMode="always"
                                            :onEnterKey="TimKiem"
                                            width="100%"
                                        >
                                            <DxValidator>
                                                <DxRequiredRule
                                                    message="Số điện thoại không được bỏ trống!"
                                                />
                                                <DxStringLengthRule
                                                    :max="128"
                                                    message="Số điện thoại không được vượt quá 128 ký tự!"
                                                />
                                            </DxValidator>
                                        </DxTextBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else-if="KieuTimKiem == 'MaTraCuu'">
                            <div class="xs12">
                                <div class="row mt-4">
                                    <div class="xs12">
                                        <DxTextBox
                                            v-model="MaChuyenDi"
                                            label="Mã chuyến đi"
                                            labelMode="floating"
                                            styling-mode="outlined"
                                            validationMessageMode="always"
                                            width="100%"
                                            :onEnterKey="TimKiem"
                                        >
                                            <DxValidator>
                                                <DxStringLengthRule
                                                    :max="128"
                                                    message="Mã chuyến đi không được vượt quá 128 ký tự!"
                                                />
                                            </DxValidator>
                                        </DxTextBox>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="xs12">
                                        <DxTextBox
                                            v-model="MaDatCho"
                                            label="Mã đặt chỗ"
                                            labelMode="floating"
                                            styling-mode="outlined"
                                            validationMessageMode="always"
                                            width="100%"
                                            :onEnterKey="TimKiem"
                                        >
                                            <DxValidator>
                                                <DxStringLengthRule
                                                    :max="128"
                                                    message="Mã đặt chỗ không được vượt quá 128 ký tự!"
                                                />
                                            </DxValidator>
                                        </DxTextBox>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <DxButton
                                key="mdi-XACNHAN"
                                type="default"
                                text="TÌM KIẾM"
                                styling-mode="contained"
                                width="100%"
                                @click="TimKiem"
                            />
                        </div>
                    </div>
                </div>
            </DxValidationGroup>

            <div class="row mt-5" :class="`${manHinhPC ? 'thong-tin-ve' : ''}`">
                <div class="xs12">
                    <DxAccordion
                        v-model:selected-items="VeDangChon"
                        :data-source="DanhSachVe || []"
                        :multiple="true"
                        :collapsible="true"
                        class="pb-1"
                        ref="DanhSachVe"
                        :class="`${manHinhPC ? '' : 'custom-accordion-mobile'}`"
                    >
                        <template #title="{ data }">
                            <div class="row align-center font-16">
                                {{ data.bienKiemSoat }}
                                <span class="font-bold font-24">
                                    {{ "\xa0|\xa0" }}
                                </span>
                                {{
                                    $Helper.Common.formatDateTime(
                                        data.thoiGianKhoiHanh
                                    )
                                }}
                                <span class="font-bold font-24">
                                    {{ "\xa0|\xa0" }}
                                </span>
                                {{
                                    data.tenDiemXuatPhat +
                                    " - " +
                                    data.tenDiemKetThuc
                                }}
                            </div>
                        </template>
                        <template #item="{ data: item }">
                            <ItemVePCVue :item="item" v-if="manHinhPC" />
                            <ItemVeMobileVue :item="item" v-else />
                        </template>
                    </DxAccordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
    DxButton,
    DxTextBox,
    DxValidationGroup,
    DxDateBox,
    DxSelectBox,
    DxAccordion,
} from "devextreme-vue";
import {
    DxValidator,
    DxRequiredRule,
    // DxEmailRule,
    // DxPatternRule,
    DxStringLengthRule,
} from "devextreme-vue/validator";
import ItemVePCVue from "./components/ItemVePC.vue";
import ItemVeMobileVue from "./components/ItemVeMobile.vue";
export default {
    data() {
        return {
            DanhSachKieuTimKiem: [
                {
                    id: "SoDienThoai",
                    text: "Số điện thoại",
                },
                {
                    id: "MaTraCuu",
                    text: "Mã tra cứu",
                },
            ],
            KieuTimKiem: "SoDienThoai",
            ThoiGianXuatBen: this.$Helper.Common.getStartDay(new Date()),
            BienKiemSoat: null,
            SoDienThoai: null,
            MaChuyenDi: null,
            MaDatCho: null,
        };
    },
    components: {
        DxAccordion,
        DxSelectBox,
        DxDateBox,
        DxTextBox,
        DxButton,
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        ItemVePCVue,
        ItemVeMobileVue,
    },
    computed: {
        ...mapState({
            DanhSachVe: (state) => state.TraCuu.DanhSachVe,
            VeDangChon: (state) => state.TraCuu.VeDangChon,
        }),
        ...mapGetters("ManHinh", ["manHinhPC"]),
    },

    methods: {
        ThongBaoToast(type, msg) {
            let modelToast = {
                isVisible: true,
                message: msg,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        TimKiem() {
            if (this.validate().isValid) {
                if (this.KieuTimKiem == "MaTraCuu") {
                    this.TimKiemTheoMaTraCuu();
                } else if (this.KieuTimKiem == "SoDienThoai") {
                    this.TimKiemTheoSoDienThoai();
                }
            } else {
                this.$Helper.Common.autoFocusError(this.validate());
            }
        },
        async TimKiemTheoMaTraCuu() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "TraCuu/TimKiemTheoMaTraCuu",
                    {
                        MaUngDung: null,
                        MaChuyenDi: this.MaChuyenDi,
                        MaDatCho: this.MaDatCho,
                    }
                );
                if (!rs.status) {
                    this.ThongBaoToast(
                        "error",
                        "Lấy thông tin vé thất bại! " + rs.message
                    );
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
            } finally {
                this.$stopLoading;
            }
        },
        async TimKiemTheoSoDienThoai() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "TraCuu/TimKiemTheoSoDienThoai",
                    {
                        SoDienThoaiHanhKhach: this.SoDienThoai,
                        ThoiGianXuatBen: this.ThoiGianXuatBen,
                    }
                );
                if (!rs.status) {
                    this.ThongBaoToast(
                        "error",
                        "Lấy thông tin vé thất bại! " + rs.message
                    );
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
            } finally {
                this.$stopLoading;
            }
        },
        onItemClick(e) {
            this.resetData();
        },
        resetData() {
            this.ThoiGianXuatBen = this.$Helper.Common.getStartDay(new Date());
            this.BienKiemSoat = null;
            this.SoDienThoai = null;
            this.MaChuyenDi = null;
            this.MaDatCho = null;
            this.$store.dispatch("TraCuu/ResetDanhSachVe");
        },
    },
    created() {
        this.KieuTimKiem = "SoDienThoai";
        let route = this.$route;
        if (route) {
            if (
                route?.query &&
                route?.query?.MaChuyenDi &&
                route?.query?.MaDatCho
            ) {
                this.KieuTimKiem = "MaTraCuu";
                this.MaChuyenDi = route.query.MaChuyenDi;
                this.MaDatCho = route.query.MaDatCho;
                this.TimKiemTheoMaTraCuu();
            }
        }
    },
    mounted() {
        let self = this;
        this.emitter.on("onresize", () => {
            let DanhSachVe = self.$refs.DanhSachVe.instance;
            if (DanhSachVe) {
                DanhSachVe.repaint();
            }
        });
    },
};
</script>

<style scoped>
.TraCuuLenh {
    text-align: center;
}
.widthinput {
    width: 888px;
    margin: 0 auto;
}
.header {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.thong-tin-ve {
    height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
}
.thong-tin-ve::-webkit-scrollbar {
    width: 8px;
}
.thong-tin-ve::-webkit-scrollbar-track {
    border-radius: 10px;
}
.thong-tin-ve::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
}
.thong-tin-ve::-webkit-scrollbar-thumb:active {
    background: #ccc;
}
::v-deep.dx-accordion-item-title {
    background: #dadce073;
}
::v-deep .custom-accordion-mobile .dx-accordion-item-title {
    display: block;
    height: max-content;
    padding-right: 48px;
    text-align: left;
}
::v-deep .custom-accordion-mobile .dx-accordion-item-title::before {
    height: 100%;
    display: flex;
    top: 0;
    align-items: center;
}
</style>
